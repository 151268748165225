import { Link } from "@mui/material";
import React from "react";

import { ReactComponent as LogoSVG } from "./../../images/Logo_with_text.svg";

const LogoBanner = () => {
  return (
    <div
      role="banner"
      className="flex flex-row sm:justify-center w-full pt-10 md:pt-4 pb-4 md:pb-3 md:pb-5 px-3"
    >
      <Link
        href="/"
        sx={{
          height: "24px",
        }}
      >
        <LogoSVG height={"24px"} width={"127px"} />
      </Link>
    </div>
  );
};

export default LogoBanner;
